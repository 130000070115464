import React, { ReactNode } from 'react';
import { GetServerSideProps } from 'next';
import getServerSideTranslations from 'shared/utils/pages/getServerSideTranslations';
import Error from 'shared/components/Error';
import { useBrand } from 'shared/hooks/useBrand';
import { useTheme } from 'shared/hooks/useTheme';
import { logger } from 'services/utils/logger';
import { Brand } from 'shared/types';
import { formatParameter } from 'shared/utils/formatParameter';
import { useTranslation } from 'next-i18next';
import { TranslationKey, Product } from 'shared/constants';
import { isFeatureEnabled } from 'shared/utils/featureToggle';
import getDefaultToolRoute from 'shared/utils/getDefaultToolRoute';
import { useFeature } from 'shared/hooks/useFeature';
import { FeatureToggles } from 'shared/constants/features';

import Heros from 'features/Hero';
import ProductCard from 'features/landingPage/components/ProductCard';
import Page from 'shared/components/Page';
import getTranslationKey from 'shared/utils/pages/getTranslationKey';

const SUPPORTED_BRANDS = [Brand.VOLVO, Brand.WAYLANDS];

interface Props {
  title: string;
  description: string;
  hasError: boolean;
}

const LandingPage: React.FC<Props> = ({ title, description, hasError }): React.ReactElement => {
  const theme = useTheme();
  const brand = useBrand();
  const { t: translate } = useTranslation(`${brand}.${TranslationKey.LANDING_PAGE}`);

  const getProductCards = (): ReactNode => {
    const availableProducts = {
      [Product.EV_TREE]: useFeature(FeatureToggles.FEATURE_EV_DECISION_TREE, brand),
      [Product.COMPARATOR]: useFeature(FeatureToggles.FEATURE_COMPARATOR, brand),
      [Product.DETAILED_COMPARATOR]: useFeature(FeatureToggles.FEATURE_DETAILED_COMPARATOR, brand),
    };

    return Object.keys(availableProducts).map((availableProduct, index: number) => {
      if (availableProducts[availableProduct] === true) {
        return (
          <ProductCard
            key={index}
            heading={translate(`content.${availableProduct}.title`)}
            intro={translate(`content.${availableProduct}.content`)}
            btnLink={translate(`link.${availableProduct}`)}
            btnText={translate(`content.${availableProduct}.cta`)}
            tool={availableProduct}
          />
        );
      }
    });
  };

  const Hero = Heros[brand];

  return (
    <Page pageID={Product.LANDING_PAGE} title={title} description={description} brand={brand}>
      {hasError && <Error />}

      <Hero pageId={Product.LANDING_PAGE} title={translate('hero.title')} subTitle={translate('hero.subtitle')} />

      <div className="page">
        <section>
          <div className="container">{getProductCards()}</div>
        </section>
      </div>
      <style jsx>
        {`
          .page {
            padding: 1rem;
          }

          .container {
            display: flex;
            justify-content: center;
            width: 960px;
            max-width: 100%;
            margin: auto;
            flex-direction: column;
          }

          @media screen and (min-width: 480px) {
            .container {
              flex-direction: row;
            }
          }

          @media screen and (min-width: ${theme.viewports.tablet}) {
            .page {
              display: flex;
              max-width: 1200px;
              padding: 2rem 3rem;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
            }
        `}
      </style>
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ params, locale = 'en-GB' }) => {
  const brand = formatParameter(params?.brand);
  let pageTitle: string = '';
  let pageDescription: string = '';
  const hasError = false;

  if (!isFeatureEnabled(FeatureToggles.FEATURE_LANDING_PAGE, Brand[brand.toUpperCase()])) {
    logger.warn(`[PAGE]: ${FeatureToggles.FEATURE_LANDING_PAGE} is disabled for '${brand}'`);

    return {
      redirect: {
        destination: getDefaultToolRoute(Brand[brand.toUpperCase()]),
        permanent: false,
      },
    };
  }

  if (!SUPPORTED_BRANDS.includes(Brand[brand.toUpperCase()])) {
    logger.error(`[PAGE]: '${brand}' is not supported`);

    return {
      notFound: true,
    };
  }

  pageTitle = await getTranslationKey(
    locale,
    Brand[brand.toUpperCase()],
    'common',
    Product.LANDING_PAGE,
    '.meta.title'
  );
  pageDescription = await getTranslationKey(
    locale,
    Brand[brand.toUpperCase()],
    'common',
    Product.LANDING_PAGE,
    '.meta.description'
  );

  return {
    props: {
      ...(await getServerSideTranslations(
        locale,
        [`${brand}.${TranslationKey.LANDING_PAGE}`, `${brand}.${TranslationKey.COMMON}`],
        Product.LANDING_PAGE
      )),
      title: `${pageTitle}`,
      description: `${pageDescription}`,
      hasError,
    },
  };
};

export default LandingPage;
