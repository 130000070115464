import * as React from 'react';

import { Product } from 'shared/constants';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { useTheme } from 'shared/hooks/useTheme';
import { useBrand } from 'shared/hooks/useBrand';

import { TertiaryButton } from 'shared/components/Button';

interface IProductCardProps {
  heading: string;
  intro: string;
  btnLink: string;
  btnText: string;
  tool: string;
}

const ProductCard: React.FunctionComponent<IProductCardProps> = ({ heading, intro, btnLink, btnText, tool }) => {
  const brand = useBrand();
  const theme = useTheme();
  const { logEvent } = useAnalytics(`/${brand}/${Product.LANDING_PAGE}`);

  return (
    <div className="product" data-test="content">
      <h2 className="product__title">{heading}</h2>
      <p className="product__intro">{intro}</p>
      <div className="product__btn-wrapper">
        <TertiaryButton
          isLink
          data-test="product-cta"
          onClick={async (event: React.SyntheticEvent): Promise<void> => {
            event.preventDefault();
            const target = event.currentTarget as HTMLAnchorElement;
            await logEvent(`/selected/${tool}`);
            window.location.href = target.href;
          }}
          href={btnLink}
          text={btnText}
          appendIcon
        >
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.63306L4.63306 5L1 1.36694L1.36694 1L5.36694 5L1.36694 9L1 8.63306Z" strokeWidth="0.5" />
          </svg>
        </TertiaryButton>
      </div>
      <style jsx>
        {`
          .product {
            display: flex;
            flex-direction: column;
            flex: 0 1;
            margin: ${theme.spacing.sm};
          }

          .product__title {
            font-family: ${theme.components.productCard.title.font.family};
            font-size: ${theme.components.productCard.title.font.size};
            color: ${theme.components.productCard.title.font.color};
            padding-bottom: ${theme.spacing.md};
            flex: 0 1;
            align-items: baseline;
          }

          .product__intro {
            flex: 0 1;
            color: ${theme.components.productCard.intro.font.color};
            font-size: ${theme.components.productCard.intro.font.size};
          }

          .product__btn-wrapper {
            align-self: flex-start;
            margin-top: ${theme.spacing.xxl};
          }

          @media screen and (min-width: 480px) {
            .product {
              flex: 0 1 200px;
            }
          }

          @media screen and (min-width: ${theme.viewports.tablet}) {
            .product {
              display: flex;
              flex: 1 1 300px;
              max-height: 425px;
              max-width: 300px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ProductCard;
