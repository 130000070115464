import React from 'react';
import { useTheme } from 'shared/hooks/useTheme';

interface Props {
  title?: string;
  content?: string;
  isStyled?: boolean;
  isCompact?: boolean;
  hasRetry?: boolean;
}
const Error: React.FC<Props> = ({ title, content, isStyled = false, isCompact = false, hasRetry = false }) => {
  const theme = useTheme();

  return (
    <div className={`error${isStyled ? ' error--is-styled' : ''}${isCompact ? ' error--compact' : ''}`}>
      {title !== undefined && <h1 className="error__title">{title}</h1>}

      <p>{content !== undefined ? content : 'Sorry, an error has occurred'}</p>

      {hasRetry && (
        <div className="actions">
          <a href="">Retry</a>
        </div>
      )}

      <style jsx>
        {`
          .error {
            padding: ${theme.spacing.lg};
            align-items: center;
            justify-content: center;
            text-align: center;
          }

          .error--is-styled {
            width: 600px;
            margin: 3rem auto;
            border: 1px solid ${theme.border.primary};
            border-radius: 10px;
            color: ${theme.fonts.light};
          }

          .error--compact {
            margin: 0 auto;
          }

          .error__title {
            padding: 1rem 0;
          }

          .actions {
            display: flex;
            justify-content: center;
            padding: 2rem;
          }
        `}
      </style>
    </div>
  );
};

export default Error;
